<div class="header">
  <div class="left">
    <div class="team-menu">
      <ng-container *ngIf="teams.length > 0">
        <div class="team-title hidden-mobile" (click)="openTeamMenu()">
          <ng-container *ngIf="selectedTeam">
            {{ selectedTeam.name }}
            <span class="unique_code" *ngIf="selectedTeam.uniqueCode">#{{ selectedTeam.uniqueCode }}</span>
          </ng-container>
          <ng-container *ngIf="!selectedTeam">Мои команды</ng-container>
          <span class="icon-more"></span>
        </div>
        <a class="team-title visible-mobile" [routerLink]="getTeamDetailRoute(selectedTeam && selectedTeam.id || teams[0].id)">
          <ng-container *ngIf="selectedTeam">
            {{ selectedTeam.name }}
            <span class="unique_code" *ngIf="selectedTeam.uniqueCode">#{{ selectedTeam.uniqueCode }}</span>
          </ng-container>
          <ng-container *ngIf="!selectedTeam">Мои команды</ng-container>
        </a>
      </ng-container>
      <ng-container *ngIf="teams.length === 0 && user">
        <a class="team-title" [routerLink]="createTeamRoute">Создать команду</a>
      </ng-container>
    </div>
  </div>
  <div class="center">
    <a routerLink="/">
      <img class="logo" [src]="'/assets/logo.svg'" title="MTGame" alt="MTGame" />
    </a>
  </div>
  <div class="right">
    <span class="unique_code" *ngIf="user?.uniqueCode">#{{ user.uniqueCode }}</span>
    <a href="javascript:;" class="profile-menu" *ngIf="user">
      <div class="profile-photo" [mtgMenuTriggerFor]="profileMenu" mtgMenuActiveClass="opened">
        <img [src]="user.photo.path" *ngIf="user.photo.path" class="circle-image" />
        <span class="empty-photo" *ngIf="!user.photo.path">{{ user.initials }}</span>
      </div>
      <mtg-menu #profileMenu="mtgMenu">
        <a [routerLink]="profileRoute" mtgMenuItem>Мой профиль</a>
        <a [routerLink]="profileEditRoute" mtgMenuItem>Редактировать</a>
        <a [routerLink]="profileSettingsRoute" mtgMenuItem>Настройки</a>
        <div class="divider"></div>
        <a href="javascript:;" mtgMenuItem (click)="logout()">Выйти</a>
      </mtg-menu>
    </a>
    <mtg-notification-bell *ngIf="user"></mtg-notification-bell>
  </div>
</div>
