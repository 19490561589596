import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NotificationService } from "@shared/core/services/notification.service";

@Component({
  selector: 'mtg-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss'],
})
export class UnsubscribeComponent implements OnInit {
  isLoading = true;
  isSuccess = false;
  isError = false;

  constructor(
    private route: ActivatedRoute,
    private notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    this.notificationService.unsubscribe(
      this.route.snapshot.queryParams['uid'],
      this.route.snapshot.queryParams['token'],
    ).subscribe(() => {
      this.isSuccess = true;
      this.isLoading = false;
    }, error => {
      this.isError = true;
      this.isLoading = false;
    });
  }
}
